import TimelineStep from "../model/TimelineStep";

export const status_reception = "Réception de votre demande";
export const status_etude = "Étude de votre demande de financement";
export const status_en_attente = "En attente de vos documents pour analyse complète du dossier";
export const status_analyse = "Analyse de votre dossier par notre expert";
export const status_depot_banque = "Attente décision partenaire bancaire";
export const status_edition_offre_de_pret = "Edition offre de prêt";
export const status_signature_offre_de_pret = "Offre de prêt signée";
export const status_encaisse = "Prêt financé – Mise à disposition des fonds";
export const status_refus_commercial = "Classement du dossier";
export const status_refus = "Refus du dossier";
export const status_refus_doublon = "Refus du dossier - Demande existante";
export const status_error = "Impossible de retourner le statut du dossier";

const status_reception_text = "Votre demande est prise en charge !";
const status_etude_text = "N'oubliez pas votre RDV du {{ date_heure_rdv }}"; // vars: {{ date_heure_rdv }}
const status_en_attente_text = "Rendez-vous sur ”constituer mon dossier” pour me faire parvenir vos documents.";
const status_analyse_text = "Votre dossier est entre de bonnes mains !";
const status_depot_banque_text = "Votre dossier a été déposé auprès de notre partenaire bancaire par votre conseiller.";
const status_edition_offre_de_pret_text = "Votre projet se concrétise bientôt !";
const status_signature_offre_de_pret_text = "Dernière ligne droite pour votre projet de financement";
const status_encaisse_text = "Félicitations pour votre projet !";
const status_refus_commercial_text = "Nous restons à votre disposition au ”N°vert”";
const status_refus_text = "Nous restons à votre disposition au ”N°vert”";
const status_refus_doublon_text = "Nous restons à votre disposition au ”N°vert”";
const status_error_text = "Nous restons à votre disposition au ”N°vert”";

const status_reception_hint = "Nous avons bien reçu votre demande et vous en remercions. Un de nos conseiller vous rappellera sous 24h ! Pour tout renseignement complémentaire nous sommes à votre disposition au ”N°vert”";
const status_etude_hint = "Lors de ce RDV, nous pourrons ensemble faire le point sur votre projet de financement et l'intégralité de vos besoins.";
const status_en_attente_hint = "Afin de procéder à une analyse complète de votre situation j'ai besoin que vous me fassiez parvenir vos pièces justificatives. Ces documents me permettront de vous proposer une solution sur-mesure en adéquation avec vos besoins.";
const status_analyse_hint = "Votre expert dédié analyse actuellement votre dossier afin de vous proposer la solution la plus adaptée à votre situation, aux meilleures conditions.";
const status_depot_banque_hint = "Votre conseiller a transmis votre dossier auprès de notre partenaire bancaire afin d'obtenir son accord définitif. Dès qu'une réponse aura été reçue, vous serez tenu informé de l'avancée de votre projet.";
const status_edition_offre_de_pret_hint = "Dernière ligne droite pour votre projet ! Il ne vous reste plus qu'à signer votre offre de prêt afin que vos fonds soient mis à votre disposition.";
const status_signature_offre_de_pret_hint = "Votre offre désormais signée va permettre le déblocage de vos fonds et votre projet va pouvoir se réaliser !";
const status_encaisse_hint = "Ensemble, nous avons réalisé votre projet et vos fonds sont désormais mis à votre disposition ! Votre conseiller reste à votre disposition pour toute question et/ou besoin complémentaire.";
const status_refus_commercial_hint = "Votre demande a bien été reçue par nos équipes et nous vous en remercions. Votre situation actuelle ne nous permet cependant pas de vous apporter une solution de financement. Si elle venait à évoluer, sachez que nous restons à votre entière disposition au ”N°vert”.";
const status_refus_hint = "Votre demande a bien été reçue par nos équipes et nous vous en remercions. Votre situation actuelle ne nous permet cependant pas de vous apporter une solution de financement. Si elle venait à évoluer, sachez que nous restons à votre entière disposition au ”N°vert”.";
const status_refus_doublon_hint = "Votre demande a bien été reçue par nos équipes et nous vous en remercions. Cependant, une demande avec cette adresse mail nous est déjà parvenue. Si vous souhaitez mettre à jour vos informations, nous sommes à votre disposition au ”N°vert”.";
const status_error_hint = "Votre demande a bien été reçue par nos équipes et nous vous en remercions. Votre situation actuelle ne nous permet cependant pas de vous apporter une solution de financement. Si elle venait à évoluer, sachez que nous restons à votre entière disposition au ”N°vert”.";

const timeline = {
    [status_reception]: () => new TimelineStep(1, status_reception, status_reception_text, status_reception_hint),
    [status_etude]: () => new TimelineStep(2, status_etude, status_etude_text, status_etude_hint),
    [status_en_attente]: () => new TimelineStep(3, status_en_attente , status_en_attente_text, status_en_attente_hint),
    [status_analyse]: () => new TimelineStep(4, status_analyse , status_analyse_text, status_analyse_hint),
    [status_depot_banque]: () => new TimelineStep(5, status_depot_banque , status_depot_banque_text, status_depot_banque_hint),
    [status_edition_offre_de_pret]: () => new TimelineStep(6, status_edition_offre_de_pret , status_edition_offre_de_pret_text, status_edition_offre_de_pret_hint),
    [status_signature_offre_de_pret]: () => new TimelineStep(7, status_signature_offre_de_pret , status_signature_offre_de_pret_text, status_signature_offre_de_pret_hint),
    [status_encaisse]: () => new TimelineStep(8, status_encaisse , status_encaisse_text, status_encaisse_hint),
    [status_refus_commercial]: () => new TimelineStep(4, status_refus_commercial , status_refus_commercial_text, status_refus_commercial_hint),
    [status_refus]: () => new TimelineStep(4, status_refus , status_refus_text, status_refus_hint),
    [status_refus_doublon]: () => new TimelineStep(2, status_refus_doublon , status_refus_doublon_text, status_refus_doublon_hint),
    [status_error]: () => new TimelineStep(2, status_error , status_error_text, status_error_hint),
};

export const steps = [
    status_reception,
    status_etude,
    status_en_attente,
    status_analyse,
    status_depot_banque,
    status_edition_offre_de_pret,
    status_signature_offre_de_pret,
    status_encaisse,
];

/**
 * 
 * @param string status
 * @returns boolean
 */
export function isRefused(status) {
    return [status_refus_commercial,
        status_refus,
        status_refus_doublon,
        status_error].includes(status);
}

/**
 * 
 * @param {string} status 
 * @returns TimelineStep
 */
export function map(status) {
    return timeline[status]();
}
